import { createRouter } from 'routerjs'
import { getToken, checkAuth } from '../api/authControl';
import { fetchProfile } from '../api/dashboard/profile';
import { openLogin, openSignup, openVerify, openContact } from '../components/login';
import { clearEditables, setupChangePasswordFuncs, setupInputs, setupProfileFuncs } from '../view/dashboard';
import { switchPage } from './dashboardRouting'

export const router = createRouter()
   .get('/login', (req, context) => {
      openLogin()
   })
   .get('/verify', (req, context) => {
      openVerify()
   })
   .get('/signup', (req, context) => {
      openSignup()
   })
   .get('/contact', (req, context) => {
      openContact()
   })

   .get('/dashboard', (req, context) => {
      switchPage('main')
   })
   .get('/dashboard/profile', (req, context) => {
      checkAuth()
      fetchProfile(getToken(), (res) => {
         setupInputs(res)
         setupProfileFuncs()
         switchPage('profile')
      })
   })
   .exit('/dashboard/profile', (req, context) => {
      clearEditables('#dash-profile form')
   })
   .get('/dashboard/change-password', (req, context) => {
      checkAuth()
      setupChangePasswordFuncs()
      switchPage('change-password')
   })
   .get('/dashboard/check-vin', (req, context) => {
      switchPage('check-vin')
   })
   .get('/dashboard/email-report', (req, context) => {
      switchPage('email-report')
   })
   .get('/dashboard/transactions', (req, context) => {
      switchPage('transactions')
   })
   .get('/dashboard/report', (req, context) => {
      switchPage('report')
   })
   .get('/dashboard/error', (req, context) => {
      switchPage('error')
   })
   .get('/dashboard.html', (req, context) => {
      router.navigate('/dashboard')
   })
   .run();