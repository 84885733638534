import { checkAuth, getToken } from "../api/authControl"
import { changePassword } from "../api/dashboard/change-password"
import { saveProfile } from "../api/dashboard/profile"
import { showAlert, showAlertMessage } from "../components/login"
import { router } from "../routing/mainRouting"

export const setupInputs = (data) => {
   console.log(data)
   const { username, surname, firstname, email, phone } = data
   const form = document.querySelector('#dash-profile form')
   form.onsubmit = (e) => {
      e.preventDefault()
   }
   const inputs = form.querySelectorAll('input')
   inputs[0].value = username
   inputs[1].value = `${surname} ${firstname}`
   inputs[2].value = email
   inputs[3].value = phone

   inputs.forEach(el => {
      el.readOnly = true;
   })
}

export const setupProfileFuncs = () => {
   let savable;
   const form = document.querySelector('#dash-profile form')
   const editButtons = form.querySelectorAll('.editButton')
   const saveButton = document.querySelector('#dash-profile .btn-save')

   const saveEnabled = (state) => {
      if (state === true) {
         saveButton.classList.remove('unsavable')
         savable = true
      } else {
         saveButton.classList.add('unsavable')
         savable = false
      }
   }

   saveEnabled(false)

   const activateEdits = (input) => {
      input.readOnly = false;
      input.classList.add('editable')
   }

   const saveEdits = () => {
      const position = '#alertModal'
      showAlert(position, true)

      const form = document.querySelector('#dash-profile form')
      const inputs = form.querySelectorAll('input')

      const username = inputs[0].value
      const name = inputs[1].value
      const names = name.split(' ')
      const firstname = names[1]
      const surname = names[0]
      const email = inputs[2].value
      const phone = inputs[3].value

      const data = {
         username,
         surname,
         firstname,
         email,
         phone
      }
      saveProfile(getToken(), data, (res) => {
         showAlertMessage(position, res)
      })
   }

   editButtons.forEach(el => {
      let input = el.parentElement.querySelector('input')
      el.onclick = () => {
         activateEdits(input)
         saveEnabled(true)
      }
   })

   saveButton.onclick = async () => {
      if (savable === true) {
         await checkAuth()
         await saveEdits()
         router.back()
      }
   }
}

export const setupChangePasswordFuncs = () => {
   let savable;
   const form = document.querySelector('#dash-change-password form')
   const inputs = form.querySelectorAll('input')
   const showButtons = form.querySelectorAll('.showPassword')
   const saveButton = document.querySelector('#dash-change-password .btn-save')

   const saveEnabled = (state) => {
      if (state === true) {
         saveButton.classList.remove('unsavable')
         savable = true
      } else {
         saveButton.classList.add('unsavable')
         savable = false
      }
   }
   saveEnabled(false)

   const showPassword = (input) => {
      const inputType = input.getAttribute('type') === 'password' ? 'text' : 'password';
      input.setAttribute('type', inputType);
   }

   const saveEdits = () => {
      const position = '#alertModal'
      showAlert(position, true)

      const oldPassword = inputs[0].value
      const newPassword = inputs[1].value

      const data = {
         oldPassword,
         newPassword
      }
      console.log('data', data)

      changePassword(getToken(), data, (res) => {
         showAlertMessage(position, res)
      })
   }

   form.onsubmit = (e) => {
      e.preventDefault()
   }

   showButtons.forEach(el => {
      let input = el.parentElement.querySelector('input')
      input.type = 'password'
      el.firstChild.classList.remove('ai-eye-slash-line')
      el.onclick = () => {
         showPassword(input)
         el.firstChild.classList.toggle('ai-eye-slash-line')
      }
   })

   inputs[1].oninput = () => {
      if (inputs[0].value !== '') {
         saveEnabled(true)
      }
   }
   inputs[0].oninput = () => {
      if (inputs[1].value !== '') {
         saveEnabled(true)
      }
   }

   inputs.forEach(el => {
      el.onkeyup = () => {
         console.log('el.value', el.value)
         if (el.value === '') {
            saveEnabled(false)
         }
      }
   })

   saveButton.onclick = async () => {
      if (savable === true) {
         await checkAuth()
         await saveEdits()
         router.back()
      }
   }
}

export const clearEditables = (targetForm) => {
   const form = document.querySelector(targetForm)
   const inputs = form.querySelectorAll('input')

   inputs.forEach(el => {
      el.readOnly = true;
      el.classList.remove('editable')
   })
}