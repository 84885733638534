import axios from "axios";
import { showAlertMessage } from "../../components/login";
import { apiBaseURL } from "../utils";

export const changePassword = function (accessToken, data, callback) {
   axios.post(`${apiBaseURL}/profile/change-password`, {data}, {
      withCredentials: true,
      headers: {
         Authorization: `Bearer ${accessToken}`
      }
   })
      .then((res) => {
         callback(res.data)
      })
      .catch((err) => {
         if (err.response) {
            const status = err.response.data.error.status
            const message = err.response.data.error.message
            position = '#alertModal'
            if (status !== 500) {
               showAlertMessage(position, message)
            } else {
               showAlertMessage(position, 'internal server error')
            }
         } else if (err.request) {
            showAlertMessage(position, 'Connection to the server was lost')
         } else {
            showAlertMessage(position, 'Unexpected error')
         }
      })
}