import axios from "axios";
import { apiBaseURL } from "./utils";

let myToken;
export const setToken = (token) => {
   myToken = token
};
export const getToken = () => {
   return myToken
}

let Auth = false;
export const setAuth = (Ss) => {
   Auth = Ss
};
export const getAuth = () => {
   return Auth
}
// axios.defaults.headers.common['Authorization'] = `Bearer ${myToken}`

export const authenticate = () => {
   axios.get(`${apiBaseURL}/`, {
      headers: {
         Authorization: `Bearer ${myToken}`
      }
   })
      .then((res) => {
         return setAuth(true)
      })
      .catch((err) => {
         setAuth(false)
         if (err.response) {
            console.log('Authorisation Failed')
         } else if (err.request) {
            console.log(position, 'Connection to the server was lost')
         } else {
            console.log(position, 'Authorisation. Unexpected error.')
         }
      })
}


export const checkAuth = (callback) => {
   let check = async () => {
      await authenticate()
      let result = getAuth()
      if (result) {
         setAuth(true)
         fixHeader()
      } else {
         axios.get(`${apiBaseURL}/accounts/refresh-token`, { withCredentials: true })
            .then((res) => {
               setToken(res.data.accessToken)
               setAuth(true)
               fixHeader()
            })
            .catch((err) => {
               setAuth(false)
               fixHeader()
               if (err.response) {
                  console.log('Authorisation Failed')
               } else if (err.request) {
                  console.log(position, 'Connection to the server was lost')
               } else {
                  console.log(position, 'Authorisation. Unexpected error.')
               }
            })
      }
   }
   check()
}



export const fixHeader = () => {
   const headerLoggedOut = document.querySelector('.header-loggedOut')
   const headerLoggedIn = document.querySelector('.header-loggedIn')
   let result = getAuth()
   if (result) {
      console.log('result', result)
      headerLoggedIn.classList.remove('hidden')
      headerLoggedOut.classList.add('hidden')
   } else {
      headerLoggedOut.classList.remove('hidden')
      headerLoggedIn.classList.add('hidden')
   }
}