export const switchPage = (page) =>{
   const oldPages = document.querySelectorAll('.dashboard_dash')
   const newPage = document.querySelector(`#dash-${page}`)
   oldPages.forEach(page =>{
      page.classList.remove('dashboard_view')
   })
   newPage.classList.add('dashboard_view')
} 

// export const dashRouter = createRouter({basePath: '/dashboard'})
// .get('/', (req, context)=>{
//    switchPage('main')
// })
// .get('/profile', (req, context)=>{
//    switchPage('profile')
// })
// .get('/change-password', (req, context)=>{
//    switchPage('change-password')
// })
// .get('/check-vin', (req, context)=>{
//    switchPage('check-vin')
// })
// .get('/email-report', (req, context)=>{
//    switchPage('email-report')
// })
// .get('/transactions', (req, context)=>{
//    switchPage('transactions')
// })
// .get('/report', (req, context)=>{
//    switchPage('report')
// })
// .get('/error', (req, context)=>{
//    switchPage('error')
// })
// .run();