import { router } from '../routing/mainRouting'

const signupModal = document.querySelector('#signUpModal')

const loginModal = document.querySelector('#loginModal')
const verifyModal = document.querySelector('#verifyModal')
const contactModal = document.querySelector('#contactModal')

const modals = document.querySelectorAll('.modal')
const modalOverlay = document.querySelector('.overlay_modal')
const modalCloser = document.querySelector('.modal_closer')
const alertOverlay = document.querySelector('.overlay_alert')
const header = document.querySelector('header')


const openModal = (modal,) => {
   modal.classList.add('modalActive')
   modalOverlay.classList.add('visible')
   modalCloser.classList.remove('hidden')
   header.classList.add('hidden')
}

const closeModal = (modal) => {
   modal.classList.remove('modalActive')
   modalOverlay.classList.remove('visible')
   modalCloser.classList.add('hidden')
   header.classList.remove('hidden')
   router.back()
}

const closeModalAll = (noNavigate=false) => {
   modals.forEach(el => {
      el.classList.remove('modalActive')
   });
   modalOverlay.classList.remove('visible')
   modalCloser.classList.add('hidden')
   header.classList.remove('hidden')
   if (noNavigate === false) {
      router.back()
   }
}

const closeModalAll2 = (noNavigate=false) => {
   modals.forEach(el => {
      el.classList.remove('modalActive')
   });
   modalOverlay.classList.remove('visible')
   modalCloser.classList.add('hidden')
   header.classList.remove('hidden')
   if (noNavigate === false) {
      // router.back()
   }
}

const openAlert = (modal) => {
   modal.classList.add('modalActive')
   alertOverlay.classList.add('visible')
   header.classList.add('hidden')
}

const closeAlert = (modal) => {
   modal.classList.remove('modalActive')
   alertOverlay.classList.remove('visible')
   header.classList.remove('hidden')
}

export const openSignup = () => {
   openModal(signupModal)
}

export const openLogin = () => {
   openModal(loginModal)
}
export const openVerify = () => {
   openModal(verifyModal)
}

export const openContact = () => {
   openModal(contactModal)
}

// const modalOverlay = document.querySelector('.overlay_modal')
modalCloser.onclick = () => {
   console.log("Calling Closer()")
   closeModalAll(false)
}

alertOverlay.onclick = () => {
   const alert = document.querySelector('#alertModal')
   closeAlert(alert)
}

const passwordInputs = document.querySelectorAll('.input_password');

passwordInputs.forEach(el => {
   const toggle = el.querySelector('.togglePswd')
   const input = el.querySelector('input')

   toggle.addEventListener('click', (e) => {
      toggle.classList.toggle('ai-eye-slash');
      const inputType = input.getAttribute('type') === 'password' ? 'text' : 'password';
      input.setAttribute('type', inputType);
   })
})



//Alert Controller



export const showAlert = (position, noNavigate=false) => {
   const alert = document.querySelector(position)
   console.log("Alert: ", alert)
   const messageBox = alert.querySelector('.alertBox h2')
   messageBox.innerHTML = `<div class="site-loader"></div>`;
   // closeModalAll(noNavigate)
   openAlert(alert);
}

export const showAlert2 = (position, noNavigate=false) => {
   const alert = document.querySelector(position)
   console.log("Alert: ", alert)
   const messageBox = alert.querySelector('.alertBox h2')
   messageBox.innerHTML = `<div class="site-loader"></div>`;
   closeModalAll2(noNavigate)
   openAlert(alert);
}

export const showAlertMessage = (position, message) => {
   const alert = document.querySelector(position)
   const messageBox = alert.querySelector('.alertBox h2')
   messageBox.innerHTML = message;
   setTimeout(() => {
      closeAlert(alert)
   }, 2000);
}

export const showAlertMessageAndClose = (position, message) => {
   const alert = document.querySelector(position)
   const messageBox = alert.querySelector('.alertBox h2')
   messageBox.innerHTML = message;
   setTimeout(() => {
      closeModalAll(false)
      // closeAlert(alert)
   }, 2000);
}

export const reOpenSignup = (position) => {
   const alert = document.querySelector(position)
   closeModal(alert)
   openModal(signupModal)
}

export const reOpenLogin = (position) => {
   const alert = document.querySelector(position)
   closeModal(alert)
   openModal(loginModal)
}
export const reOpenVerify = (position) => {
   const alert = document.querySelector(position)
   closeModal(alert)
   openModal(verifyModal)
}