import axios from "axios";
import { apiBaseURL } from "../utils";


export const fetchProfile = function (accessToken, callback) {
   axios.get(`${apiBaseURL}/profile`, {
      withCredentials: true,
      headers: {
         Authorization: `Bearer ${accessToken}`
      }
   })
      .then((res) => {
         callback(res.data)
      })
      .catch((err) => {
         if (err.response) {
            console.log(err)
         } else if (err.request) {
            console.log('Connection to the server was lost')
         } else {
            console.log('Authorisation. Unexpected error.')
         }
      })
}

export const saveProfile = function (accessToken, data, callback) {
   axios.post(`${apiBaseURL}/profile/update`, {data}, {
      withCredentials: true,
      headers: {
         Authorization: `Bearer ${accessToken}`
      }
   })
      .then((res) => {
         callback(res.data)
      })
      .catch((err) => {
         if (err.response) {
            console.log(err)
         } else if (err.request) {
            console.log('Connection to the server was lost')
         } else {
            console.log('Authorisation. Unexpected error.')
         }
      })
}